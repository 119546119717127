<template>
  <div class="vdd">
    <div class="hero">
      <h1>Vie-nyle DD</h1>
    </div>

    <div class="strip">
      <p>January 2022 <span>✹</span> Still now</p>
      <p class="index">004</p>
    </div>

    <div class="info">
      <div class="context">
        <h2>The context</h2>
        <p>
          In january 2022, the Protagomix association, who make cultural events
          to promote urban culture, contact me to realize a website for their
          exposition " Vie-nyle Défectueux Détourné " which take place in
          different cities all around the world.
        </p>
        <p>
          Vie-nyle Défectueux Détourné is an ecologic and artistic project which
          aims to recycle old vinyls to transform them into pieces of art by
          artists from all over the world.
        </p>
      </div>
      <div class="right-info">
        <div class="contribution">
          <h2>My contribution</h2>
          <p>
            For this project, I had to create a brand identity from scratch, the
            UI design and then code it. So I came up with this neo-memphis style
            for the VDD identity wich I think match well the spirit of the
            project. For the development and since I had a short deadline
            taking that I had a week to put something online into account, I
            choose to develop a landing page. But I'm currently working in the
            main website wich is a wordpress website.
          </p>
        </div>

        <div class="result">
          <h2>The result</h2>
          <p>
            I think the clients and I are satisfied with the quality of the
            solution I came up with and we're currently still in touch to launch
            the main website.
          </p>
          <a
            href="http://vdd-expo.com/"
            target="_blank"
            rel="noopener noreferrer"
            >Visit the project right here.</a
          >
        </div>
      </div>
    </div>

    <div class="mockup-holder">
      <div class="mockup">
        <img
          src="../../assets/images/projects/vdd.jpg"
          alt="the bfc market design"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.vdd {
  margin: 5rem 2rem;
  @media screen and (max-width: 800px) {
    margin: 0 1rem;
  }
}

.link,
.link a {
  text-decoration: underline;
}

h1 {
  font-size: 15vw;
  line-height: 100%;
  margin: 0;
  @media screen and (min-width: 1700px) {
    font-size: 25rem;
  }
  @media screen and (max-width: 800px) {
    font-size: 8rem;
  }
}

a {
  color: $blue;
  &:hover {
    text-decoration: underline;
  }
}

h2 {
  margin: 0;
  font-family: $font-body;
  color: $blue;
  font-size: 4rem;
  font-variation-settings: "wght" 400;
  font-weight: 400;
  @media screen and (max-width: 800px) {
    font-size: 3rem;
  }
}

.hero {
  height: 40vh;
  display: flex;
  align-items: flex-end;
}

.info {
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
  p {
    padding: 1rem 0;
  }
  @media screen and (max-width: 800px) {
    flex-direction: column;
  }
}
.context,
.right-info {
  width: 40%;
  @media screen and (max-width: 800px) {
    width: 100%;
  }
}

.strip {
  display: flex;
  justify-content: space-between;
  border-top: 2px solid black;
  border-bottom: 2px solid black;
  p {
    padding: 0.5rem 0;
    font-size: 5rem;
    font-variation-settings: "wght" 300;
    @media screen and (max-width: 800px) {
      font-size: 2rem;
    }
  }
  .index {
    text-align: end;
  }
  span {
    color: $green;
  }
}

.mockup-holder {
  margin: 5rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.mockup {
  width: 50%;
  max-width: 100rem;
  img {
    width: 100%;
  }
  @media screen and (max-width: 800px) {
    width: 100%;
  }
}
</style>

<script>
export default {
  name: "VDD",
};
</script>
